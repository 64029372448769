import Typesense from "typesense"

const getTypesenseClient = () => {
  return new Typesense.Client({
    nodes: [{ url: window.APP_TYPESENSE_HOST }],
    apiKey: window.APP_TYPESENSE_KEY,
    connectionTimeoutSeconds: 2,
  })
}

const getTypesenseCollectionName = (name) => {
  return `${name}:${window.APP_TYPESENSE_ENV}`
}

const searchTypesense = async ({ text, language, limit }) => {
  const searches = {
    sites: {
      query: {
        collection: getTypesenseCollectionName(`site`),
        q: text,
        query_by: `name_sv,name_${language}`,
        include_fields: `name_sv,name_${language},id,path`,
        sort_by: `importance:desc,_text_match:desc`,
      },
      normalizer: (hit) => ({
        id: hit.id,
        title: hit[`name_${language}`] || hit.name_sv,
        url: hit.path,
      }),
    },
    news: {
      query: {
        collection: getTypesenseCollectionName(`news`),
        q: text,
        query_by: `title_sv,title_${language}`,
        include_fields: `title_sv,title_${language},path`,
      },
      normalizer: (hit) => ({
        title: hit[`title_${language}`] || hit.title_sv,
        url: hit.path,
      }),
    },
    articles: {
      query: {
        collection: getTypesenseCollectionName(`article`),
        q: text,
        query_by: `title_sv,title_${language}`,
        include_fields: `title_sv,title_${language},path`,
      },
      normalizer: (hit) => ({
        title: hit[`title_${language}`] || hit.title_sv,
        url: hit.path,
      }),
    },
    events: {
      query: {
        collection: getTypesenseCollectionName(`event`),
        q: text,
        query_by: `title_sv,title_${language}`,
        include_fields: `title_sv,title_${language},path`,
      },
      normalizer: (hit) => ({
        title: hit[`title_${language}`] || hit.title_sv,
        url: hit.path,
      }),
    },
    miniguides: {
      query: {
        collection: getTypesenseCollectionName(`miniguide`),
        q: text,
        query_by: `title_sv,title_${language}`,
        include_fields: `title_sv,title_${language},path`,
      },
      normalizer: (hit) => ({
        title: hit[`title_${language}`] || hit.title_sv,
        url: hit.path,
      }),
    },
  }

  const data = await getTypesenseClient().multiSearch.perform(
    { searches: Object.values(searches).map((i) => i.query) },
    {
      filter_by: `published:=true`, // TODO: skip the condition in case of super_admin
      limit,
    }
  )

  const results = {}
  Object.keys(searches).map(
    (name, i) =>
      (results[name] = data.results[i].hits.map((h) =>
        searches[name].normalizer(h.document)
      ))
  )

  return results
}

export { getTypesenseClient, getTypesenseCollectionName, searchTypesense }
