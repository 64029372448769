import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"
import flatpickr from "flatpickr"
import classNames from "classnames"
import { nanoid } from "nanoid"
import { Swedish } from "flatpickr/dist/l10n/sv.js"
import { German } from "flatpickr/dist/l10n/de.js"
import { Dutch } from "flatpickr/dist/l10n/nl.js"
import { Polish } from "flatpickr/dist/l10n/pl.js"
import { Finnish } from "flatpickr/dist/l10n/fi.js"

import Stimulus from "../utils/stimulus"
import modal from "../utils/modal"
import { fetchSiteApi } from "../utils/site-api"

const FLATPICKR_LOCALE = {
  en: null,
  sv: Swedish,
  de: German,
  nl: Dutch,
  pl: Polish,
  fi: Finnish,
}[document.documentElement.lang]

class FormInput extends Controller {
  static values = {
    choices: Boolean,
    choicesMax: String,
    choicesMaxText: String,
    choicesNowrap: Boolean,
    choicesSize: String,
    choicesLoadingText: String,
    choicesNoResultsText: String,
    choicesNoChoicesText: String,
    choicesTagsUrl: String,
    choicesTagsText: String,
    choicesTagsOrganization: String,
    flatpickr: Boolean,
    flatpickrMode: String,
    flatpickrSeparator: String,
  }

  #tagName = this.element.tagName.toLowerCase()
  #tagType = this.element.type

  #selectBlankOptionText = ``
  #choices = null
  #choicesHtml = ``
  #choicesTagsButton = null

  #isDateTime = [`date`, `time`, `datetime-local`].includes(this.#tagType)
  #dateFormat = `Y-m-d`
  #flatpickr = null

  connect() {
    if (this.#tagName == `select` || this.choicesValue) this.initSelect()
    else if (this.#isDateTime || this.flatpickrValue) this.initDateTime()
  }

  disconnect() {
    // TODO: these are useless except `turbo:before-cache` part
    if (this.#tagName == `select` || this.choicesValue) this.deinitSelect()
    else if (this.#isDateTime || this.flatpickrValue) this.deinitDateTime()
  }

  // select

  initSelect = () => {
    if (this.element.options[0]?.value === ``)
      this.#selectBlankOptionText = this.element.options[0].label

    if (!this.choicesValue) {
      this.toggleCustomPlaceholderClass()
      this.element.addEventListener(`change`, this.toggleCustomPlaceholderClass)
      this.element.addEventListener(`blur`, this.toggleCustomPlaceholderClass)
    } //
    else if (!this.#choices) {
      this.element.addEventListener(`focus`, this.choicesFocus)

      let tagsDomId = null

      if (this.choicesTagsUrlValue) {
        tagsDomId = `a${nanoid()}`

        this.element.addEventListener(`search`, (e) => {
          // TODO: impossible to add a tag if dropdown contains results; idea: display message in the dropdown "click ENTER to add `abc` tag"
          const tagsEl = document.getElementById(tagsDomId)
          if (tagsEl) {
            this.#choicesTagsButton = document.createElement(`button`)
            this.#choicesTagsButton.onclick = this.choicesTagsAdd
            this.#choicesTagsButton.classList.add(`--tags`)
            this.#choicesTagsButton.type = `button`
            this.#choicesTagsButton.dataset.thisValue = e.detail.value
            this.#choicesTagsButton.innerHTML =
              this.choicesTagsTextValue.replace(
                `{{TEXT}}`,
                `<b>${e.detail.value}</b>`
              )
            tagsEl.replaceChildren(this.#choicesTagsButton)
          }
        })
      }

      const options = {
        shouldSort: false,
        removeItemButton: true,
        allowHTML: true,
        loadingText: this.choicesLoadingTextValue,
        noChoicesText: this.choicesNoChoicesTextValue,
        noResultsText: tagsDomId
          ? `<span id="${tagsDomId}"></span>`
          : this.choicesNoResultsTextValue,
        classNames: {
          containerOuter: classNames({
            choices: true,
            [`is-size-${this.choicesSizeValue}`]: true,
            "is-nowrap": this.choicesNowrapValue,
          }),
        },
        fuseOptions: {
          threshold: tagsDomId ? 0 : 0.6,
        },
        callbackOnCreateTemplates: () => ({
          input: (...args) =>
            Object.assign(
              Choices.defaults.templates.input.call(this, ...args),
              {
                onkeydown: tagsDomId && this.choicesTagsInputKeyDown,
              }
            ),
        }),
      }

      if (this.hasChoicesMaxValue) {
        options.maxItemCount = this.choicesMaxValue

        if (this.hasChoicesMaxTextValue) {
          options.maxItemText = (max) =>
            this.choicesMaxTextValue.replace(`[max]`, max)
        }
      }

      this.#choicesHtml = this.element.outerHTML
      this.#choices = new Choices(this.element, options)

      document.addEventListener(`turbo:before-cache`, this.choicesDestroy)
    }

    // TODO: check if moving this up^ makes works better
    // if (this.choicesValue)
    //   document.addEventListener(`turbo:before-cache`, this.choicesDestroy)
  }

  deinitSelect = () => {
    if (!this.choicesValue) {
      this.element.removeEventListener(`change`, this.toggleCustomPlaceholderClass) // eslint-disable-line prettier/prettier
      this.element.removeEventListener(`blur`, this.toggleCustomPlaceholderClass) // eslint-disable-line prettier/prettier
    } else {
      document.removeEventListener(`turbo:before-cache`, this.choicesDestroy)
    }
  }

  toggleCustomPlaceholderClass = () => {
    this.element.classList.toggle(`--placeholder`, !this.element.value)
  }

  choicesFocus = () => {
    if (!this.#choices) return

    this.#choices.containerOuter.focus()
  }

  choicesTagsInputKeyDown = (e) => {
    if (e.key == `Enter`) this.choicesTagsAdd()
  }

  setDisabled = (disabled) => {
    this.element.disabled = disabled
    if (this.#choices) this.#choices[disabled ? `disable` : `enable`]()
  }

  clearOptions = () => {
    // TODO: probably don't do both if Choices inited
    this.element.length = 0
    if (this.#choices) this.#choices.clearStore()

    if (this.#selectBlankOptionText) {
      this.element.add(new Option(this.#selectBlankOptionText, ``))

      if (this.#choices && !this.element.multiple)
        this.#choices.setChoices(
          [
            {
              value: ``,
              text: this.#selectBlankOptionText,
              selected: true,
              placeholder: true,
            },
          ],
          `value`,
          `text`,
          false
        )
    }
  }

  addOption = (value, text, selected = false) => {
    // TODO: probably don't do both if Choices inited
    this.element.add(new Option(text, value, selected, selected))

    if (this.#choices)
      this.#choices.setChoices(
        [{ value, text, selected }],
        `value`,
        `text`,
        false
      )
  }

  setValue = (value) => {
    if (this.#choices) {
      this.#choices.removeActiveItems()
      this.#choices.setChoiceByValue(value)
    }
  }

  choicesTagsAdd = () => {
    if (!this.#choicesTagsButton) return

    this.#choicesTagsButton.disabled = true

    fetchSiteApi({
      url: this.choicesTagsUrlValue,
      method: `POST`,
      body: {
        tag: {
          type: `locality`,
          name: this.#choicesTagsButton.dataset.thisValue,
          organization_id: this.choicesTagsOrganizationValue,
        },
      },
    })
      .then((r) => r.json())
      .then((data) => {
        this.#choices.setChoices(
          [
            {
              value: data.id,
              label: data.name,
              selected: true,
            },
          ],
          `value`,
          `label`,
          false
        )

        this.#choices.clearInput()
      })
      .catch(() => {
        modal.show(`global-error-modal`)
      })
      .finally(() => {
        this.#choicesTagsButton.disabled = false
      })
  }

  choicesDestroy = () => {
    this.element.removeEventListener(`focus`, this.choicesFocus)
    this.#choices.destroy()
    this.element.outerHTML = this.#choicesHtml
  }

  initDateTime = () => {
    this.element.type = `text`
    this.element.dataset.thisType = this.#tagType
    this.toggleCustomPlaceholderClass()
    this.element.addEventListener(`focus`, this.dateTimeFocus)
    this.element.addEventListener(`blur`, this.dateTimeBlur)
    this.element.addEventListener(`change`, this.toggleCustomPlaceholderClass)

    if (this.flatpickrValue) {
      const setElementValue = (selectedDates, dateStr, instance) => {
        this.element.value = selectedDates
          .map((d) => instance.formatDate(d, this.#dateFormat))
          .join(this.flatpickrSeparatorValue)
      }

      this.#flatpickr = flatpickr(this.element, {
        locale: FLATPICKR_LOCALE,
        mode: this.flatpickrModeValue || `single`,
        defaultDate: (this.element.value || ``).split(
          this.flatpickrSeparatorValue
        ),
        onReady: setElementValue,
        onChange: setElementValue,
      })
    }
  }

  deinitDateTime = () => {
    this.element.type = this.#tagType
    this.#flatpickr?.destroy()
    this.element.removeEventListener(`focus`, this.dateTimeFocus)
    this.element.removeEventListener(`blur`, this.dateTimeBlur)
    this.element.removeEventListener(`change`, this.toggleCustomPlaceholderClass)  // eslint-disable-line prettier/prettier
  }

  dateTimeFocus = () => {
    if (!this.flatpickrValue) {
      this.element.type = this.#tagType
      this.element.showPicker()
    }
  }

  dateTimeBlur = () => {
    this.toggleCustomPlaceholderClass()
    if (!this.flatpickrValue && !this.element.value) this.element.type = `text`
  }
}

Stimulus.register(`form-input`, FormInput)
