// images

require.context(`../../new_images`, true)

// stylesheets

import "../../stylesheets/new-application.scss"

// external

// import "..."

// internal

import "../common/base/polyfills"
import "../common/base/rails"
import "../common/base/turbo"
import "../common/base/linkable"

import "../common/atoms/media"
import "../common/atoms/form-field"
import "../common/atoms/form-input"
import "../common/atoms/form-file"
import "../common/atoms/form-hint"
import "../common/atoms/form-failure"
import "../common/atoms/overflow-mask"
import "../common/atoms/reset-cookie"
import "../common/atoms/explore-bounds"
import "../common/atoms/fix-anchors"

import "../common/components/button"
import "../common/components/flash"
import "../common/components/global-notice"
import "../common/components/modal"
import "../common/components/default-modal"
import "../common/components/confirm-modal"
import "../common/components/share-modal"
import "../common/components/tabbed-content"
import "../common/components/map-new"
import "../common/components/header"
import "../common/components/search"
import "../common/components/cookie-bar"
import "../common/components/location-tracker"
import "../common/components/slideshow"
import "../common/components/carousel"
import "../common/components/cards-carousel"
import "../common/components/comment"
import "../common/components/rating"
import "../common/components/hero"
import "../common/components/navbar"
import "../common/components/stops"
import "../new-application/components/native-app"

import "../new-application/pages/explore-page"
import "../new-application/pages/site-page"
import "../new-application/pages/guide-page"
import "../new-application/pages/contents-page"
import "../new-application/pages/search-page"
import "../new-application/pages/region-page"
