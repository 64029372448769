import { Controller } from "stimulus"
import Cookies from "js-cookie"

import Stimulus from "../utils/stimulus"

class CookieBar extends Controller {
  static targets = [`script`]
  static values = { name: String }

  accept() {
    this.set(`accept`)

    const script = document.createElement(`script`)
    script.type = `text/javascript`
    script.text = this.scriptTarget.innerHTML
    document.body.appendChild(script)
  }

  deny() {
    this.set(`deny`)
  }

  set = (value) => {
    Cookies.set(this.nameValue, value, { expires: 365 })
    this.element.classList.add(`hidden`)
  }
}

Stimulus.register(`cookie-bar`, CookieBar)
