import { Controller } from "stimulus"
import Cookies from "js-cookie"

import Stimulus from "../utils/stimulus"

class ResetCookie extends Controller {
  static values = { name: String }

  do() {
    Cookies.remove(this.nameValue)
    window.scrollTo({ top: 0, behavior: `instant` })
    window.location.reload()
  }
}

Stimulus.register(`reset-cookie`, ResetCookie)
